<script setup lang="ts">
const { data: page } = await useAsyncData('index', () => queryContent('/').findOne())
if (!page.value) {
  throw createError({ statusCode: 404, statusMessage: 'Página não encontrada', fatal: true })
}

const pgsPhotosUi = {
  body: {
    base: '',
    background: '',
    padding: '',
  },
  container: 'p-0',
};

useSeoMeta({
  titleTemplate: '',
  title: page.value.title,
  ogTitle: page.value.title,
  description: page.value.description,
  ogDescription: page.value.description
});
</script>

<template>
  <div v-if="page">
    <AppLandingHero
      :title="page.hero.title"
      :description="page.hero.description"
      :links="page.hero.links"
    >
      <div class="absolute inset-0 landing-grid z-[-1] [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" />

      <template #headline>
        <BaseBadge
          v-if="page.hero.headline"
          variant="subtle"
          size="lg"
          class="relative rounded-full font-semibold"
        >
          <NuxtLink
            :to="page.hero.headline.to"
            target="_blank"
            class="focus:outline-none"
            tabindex="-1"
          >
            <span
              class="absolute inset-0"
              aria-hidden="true"
            />
          </NuxtLink>

          {{ page.hero.headline.label }}

          <UIcon
            v-if="page.hero.headline.icon"
            :name="page.hero.headline.icon"
            class="ml-1 w-4 h-4 pointer-events-none"
          />
        </BaseBadge>
      </template>
    </AppLandingHero>

    <AppLandingSection class="!pt-0">
      <LandingImage src="/assets/photos/20240331203850_IMG_6590.jpg" class="filter grayscale" />
    </AppLandingSection>

    <AppLandingSection
      id="pgs"
      :title="page.pgs.title"
      align="left"
    >
      <template #description>
        <span v-html="page.pgs.description" />
      </template>
      <AppLandingGrid>
        <AppLandingCard
          class="col-span-6 row-span-2"
          :ui="pgsPhotosUi"
        >
          <template #container>
            <img
              src="/landing/images/536df0f4.png"
              class="w-full rounded-md saturate-50"
            >
          </template>
        </AppLandingCard>

        <AppLandingCard
          class="col-span-6 row-span-3"
          :ui="pgsPhotosUi"
        >
          <template #container>
            <img
              src="/landing/images/b993212a.png"
              class="w-full rounded-md saturate-50"
            >
          </template>
        </AppLandingCard>
        <AppLandingCard
          class="col-span-6 row-span-4"
          :ui="pgsPhotosUi"
        >
          <template #container>
            <img
              src="/landing/images/89c04457.png"
              class="w-full rounded-md saturate-50"
            >
          </template>
        </AppLandingCard>
        <AppLandingCard
          class="col-span-6 row-span-3"
          :ui="pgsPhotosUi"
        >
          <template #container>
            <img
              src="/landing/images/93fedde4.png"
              class="w-full rounded-md saturate-50"
            >
          </template>
        </AppLandingCard>
      </AppLandingGrid>
    </AppLandingSection>

    <AppLandingSection
      v-for="(section, index) in page.sections"
      :key="index"
      :title="section.title"
      :description="section.description"
      :align="section.align"
    >
      <LandingImage :src="section.image" :class="section.imageClasses" />
    </AppLandingSection>

    <AppLandingSection
      :title="page.features.title"
      :description="page.features.description"
    >
      <AppPageGrid>
        <ULandingCard
          v-for="(item, index) in page.features.items"
          :key="index"
          v-bind="item"
        />
      </AppPageGrid>
    </AppLandingSection>

    <AppLandingSection>
      <AppLandingCTA
        v-bind="page.cta"
        class="bg-gray-100/50 dark:bg-gray-800/50"
      />
    </AppLandingSection>
  </div>
</template>

<style scoped>
.landing-grid {
  background-size: 100px 100px;
  background-image:
    linear-gradient(to right, rgb(var(--color-gray-200)) 1px, transparent 1px),
    linear-gradient(to bottom, rgb(var(--color-gray-200)) 1px, transparent 1px);
}
.dark {
  .landing-grid {
    background-image:
      linear-gradient(to right, rgb(var(--color-gray-800)) 1px, transparent 1px),
      linear-gradient(to bottom, rgb(var(--color-gray-800)) 1px, transparent 1px);
  }
}
</style>